@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

* {
    box-sizing: border-box;
    margin: 0;
}

body {
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.4px;
}

:root {
    --white: rgb(255, 255, 255);
    --bg: rgb(245, 245, 245);
    --bg2: rgb(237, 237, 237);
    --bg3: rgb(214, 214, 214);
    --text: rgb(33, 33, 33);
    --primary: rgb(224, 132, 209);

    --border-radius: 8px;

    --sm-spacing: 8px;
    --md-spacing: 16px;
    --lg-spacing: 32px;

    --sm: 59.5em;
    --md: 48em;
    --lg: 64em;

    --box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
        0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11), 0 8px 16px rgba(0, 0, 0, 0.11);
}

.table-container {
    margin: 0 auto;
    margin-top: 200px;
    max-width: var(--sm);
    padding: var(--sm-spacing);
    background: var(--bg2);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}